import { Vector3 } from "three";
import { create } from "zustand";
import { createWithEqualityFn } from "zustand/traditional";
import { subscribeWithSelector } from "zustand/middleware";

interface State {
  serviceHorizontalComp: boolean;
  prefooter: any;
  footerTwo: any;
  duo: any;
  experience: any;
  excelence: any;
  cases: any;
  fluidCanvas: any;
  sharedCanvas: any;
  casesBG: any;
  outputPassRef: any;
  finalPassRef: any;
  mouse: any;
  casesHover: any;
  isMobile: any;
  websiteLoading: boolean;
  firstRender: boolean;
  fromPopState: boolean;
  counterTemp: boolean;
  setPrefooter: () => void;
  setFooterTwo: () => void;
  setDuo: () => void;
  setExperience: () => void;
  setExcelence: () => void;
  setService: () => void;
  setCases: () => void;
  setFluidCanvas: () => void;
  setSharedCanvas: () => void;
  setCasesBG: () => void;
  setOutputPassRef: () => void;
  setFinalPassRef: () => void;
  setMouse: () => void;
  setCasesHover: () => void;
  setIsMobile: () => void;
  setWebsiteLoading: () => void;
  setFirstRender: () => void;
  setFromPopState: () => void;
}

export const useRefStore = createWithEqualityFn<State>()(
  subscribeWithSelector((set, get) => ({
    serviceHorizontalComp: false,
    prefooter: null,
    footerTwo: null,
    duo: null,
    experience: null,
    excelence: null,
    cases: null,
    fluidCanvas: null,
    sharedCanvas: null,
    outputPassRef: null,
    finalPassRef: null,
    mouse: null,
    casesHover: false,
    isMobile: null,
    casesBG: new Vector3(1, 1, 1),
    websiteLoading: true,
    firstRender: true,
    fromPopState: false,
    counterTemp: true,
    setPrefooter: () => set((state) => ({ prefooter: state.prefooter })),
    setFooterTwo: () => set((state) => ({ footerTwo: state.footerTwo })),
    setDuo: () => set((state) => ({ duo: state.duo })),
    setExperience: () => set((state) => ({ experience: state.experience })),
    setExcelence: () => set((state) => ({ excelence: state.excelence })),
    setService: () => set((state) => ({ serviceHorizontalComp: true })),
    setCases: () => set((state) => ({ cases: state.cases })),
    setFluidCanvas: () => set((state) => ({ fluidCanvas: state.fluidCanvas })),
    setSharedCanvas: () =>
      set((state) => ({ sharedCanvas: state.sharedCanvas })),
    setCasesBG: () => set((state) => ({ casesBG: state.casesBG })),
    setOutputPassRef: () =>
      set((state) => ({ outputPassRef: state.outputPassRef })),
    setFinalPassRef: () =>
      set((state) => ({ finalPassRef: state.finalPassRef })),
    setMouse: () => set((state) => ({ mouse: state.mouse })),
    setCasesHover: () => set((state) => ({ casesHover: state.casesHover })),
    setIsMobile: () => set((state) => ({ isMobile: state.isMobile })),
    setWebsiteLoading: () =>
      set((state) => ({ websiteLoading: state.websiteLoading })),
    setFirstRender: () => set((state) => ({ firstRender: state.firstRender })),
    setFromPopState: () =>
      set((state) => ({ fromPopState: state.fromPopState })),
  }))
);
