"use client";
import React, {
  useEffect,
  useRef,
  forwardRef,
  PropsWithChildren,
  RefObject,
} from "react";
import gsap from "gsap";
import { useRefStore } from "../../../store/ref-store";

export const MagneticCursor = forwardRef<HTMLElement, PropsWithChildren>(
  ({ children }, ref: RefObject<HTMLElement>) => {
    const magnetic = useRef(null);
    const { isMobile } = useRefStore();

    useEffect(() => {
      if (isMobile !== null && !isMobile) {
        const curRef = ref?.current || magnetic.current;
        const xTo = gsap.quickTo(curRef, "x", {
          duration: 1,
          ease: "elastic.out(1, 0.3)",
        });
        const yTo = gsap.quickTo(curRef, "y", {
          duration: 1,
          ease: "elastic.out(1, 0.3)",
        });

        const handleMouseMove = (e) => {
          const { clientX, clientY } = e;
          const { height, width, left, top } = curRef.getBoundingClientRect();
          const x = clientX - (left + width / 2);
          const y = clientY - (top + height / 2);
          xTo(x);
          yTo(y);
        };

        const handleMouseLeave = () => {
          xTo(0);
          yTo(0);
        };

        curRef.addEventListener("mousemove", handleMouseMove);
        curRef.addEventListener("mouseleave", handleMouseLeave);

        return () => {
          curRef.removeEventListener("mousemove", handleMouseMove);
          curRef.removeEventListener("mouseleave", handleMouseLeave);
        };
      }
    }, [ref, isMobile]);

    return React.cloneElement(children as React.ReactElement, {
      ref: ref || magnetic,
    });
  }
);

MagneticCursor.displayName = "MagneticCursor";
