import { FC } from "react";
import { Icons } from "./Icons";

interface IIcon {
  name: string;
  size?: string;
  fill?: string | "#fff";
}

export const Icon: FC<IIcon> = ({ name, size = "s", fill }) => {
  const sizes = {
    xs: 16,
    s: 24,
    m: 30,
    l: 48,
  };

  const IconComponent = Icons[name];

  if (!IconComponent) return null;

  return <IconComponent size={sizes[size]} fill={fill} />;
};
