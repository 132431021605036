"use client";
import React, { createContext, useContext, useState } from "react";

const ContactContext = createContext(null);

export const ContactProvider = ({ children }) => {
  const [isContactOpen, setIsContactOpen] = useState(false);
  return (
    <ContactContext.Provider
      value={{
        isContactOpen,
        setIsContactOpen,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export const useContact = () => {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error(
      "useContact debe estar dentro del proveedor ContactProvider"
    );
  }
  return context;
};
