import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRefStore } from "../store/ref-store";
import gsap from "gsap";

import TransitionStyles from "./components/Transition/Transition.module.scss";
import BurgerStyles from "@/components/layout/Header/Burger/Burger.module.scss";

const wrapperSelector = `.${TransitionStyles.wrapper}`;
const logoSelector = `.${TransitionStyles.logo}`;

export const popStateIn = () => {
  useRefStore.setState({ fromPopState: false });

  const container: HTMLElement = document.querySelector(".generalPagesWrap");
  const mainWebWrapper: HTMLElement = document.querySelector(".mainWebWrapper");

  if (mainWebWrapper.style.backgroundColor !== "var(--background)") {
    mainWebWrapper.style.backgroundColor = "var(--background)";
  }

  const burgerElement: HTMLElement = document.querySelector(
    `.${BurgerStyles.burger}`
  );
  const burgerElementColor = getComputedStyle(burgerElement).getPropertyValue(
    "--BurguerBackground"
  );

  if (burgerElementColor !== "#4c2882") {
    gsap.set(`.${BurgerStyles.burger}`, {
      "--BurguerBackground": "var(--Primary-300, #4c2882)",
    });
  }

  const wrapper: HTMLElement = document.querySelector(
    `.${TransitionStyles.wrapper}`
  );

  window.scrollTo({ top: 0, behavior: "instant" });

  wrapper.style.opacity = "0";

  container.style.overflowY = "";
  document.body.style.overflowY = "";
  container.style.maxHeight = "";
  document.body.style.maxHeight = "";
  container.style.maxWidth = "";
  document.body.style.maxWidth = "";
};

export const animatePageIn = () => {
  const container: HTMLElement = document.querySelector(".generalPagesWrap");
  const mainWebWrapper: HTMLElement = document.querySelector(".mainWebWrapper");

  if (mainWebWrapper.style.backgroundColor !== "var(--background)") {
    mainWebWrapper.style.backgroundColor = "var(--background)";
  }

  const burgerElement: HTMLElement = document.querySelector(
    `.${BurgerStyles.burger}`
  );
  const burgerElementColor = getComputedStyle(burgerElement).getPropertyValue(
    "--BurguerBackground"
  );

  if (burgerElementColor !== "#4c2882") {
    gsap.set(`.${BurgerStyles.burger}`, {
      "--BurguerBackground": "var(--Primary-300, #4c2882)",
    });
  }

  const tl = gsap.timeline();

  tl.fromTo(
    logoSelector,
    {
      scale: 1,
      xPercent: -50,
      yPercent: -50,
    },
    {
      scale: 200,
      xPercent: 2600,
      yPercent: 3400,
      duration: 0.6,
      ease: "circ.in",
    }
  )
    .fromTo(
      container,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
        ease: "power2.in",
      },
      "<"
    )
    .fromTo(
      wrapperSelector,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.4,
        ease: "power2.in",
        onComplete: () => {
          container.style.overflowY = "";
          document.body.style.overflowY = "";
          container.style.maxHeight = "";
          document.body.style.maxHeight = "";
          container.style.maxWidth = "";
          document.body.style.maxWidth = "";
        },
      },
      "<+=75%"
    );
};

export const animatePageOut = (
  href: string,
  router: AppRouterInstance,
  fromNav: boolean,
  onClick?: () => void
) => {
  const container: HTMLElement = document.querySelector(".generalPagesWrap");

  router.prefetch(href);

  if (fromNav) {
    fromNavOut(router, href, container, onClick);
    return;
  }

  defaultOut(router, href, container);
};

const defaultOut = (
  router: AppRouterInstance,
  href: string,
  container: HTMLElement
) => {
  const tl = gsap.timeline();

  tl.fromTo(
    wrapperSelector,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.4,
      ease: "power2.out",
    }
  ).fromTo(
    logoSelector,
    {
      scale: 200,
      xPercent: 2600,
      yPercent: 3400,
    },
    {
      scale: 1,
      xPercent: -50,
      yPercent: -50,
      duration: 0.6,
      ease: "circ.out",
      onComplete: () => {
        router.push(href);
        container.style.overflowY = "hidden";
        document.body.style.overflowY = "hidden";
        container.style.maxHeight = "100vh";
        document.body.style.maxHeight = "100vh";
        container.style.maxWidth = "100vw";
        document.body.style.maxWidth = "100vw";
      },
    },
    "<+=25%"
  );
};

const fromNavOut = (
  router: AppRouterInstance,
  href: string,
  container: HTMLElement,
  onClick?: () => void
) => {
  const navItemsSelectors = [
    ".container-header > a",
    ".container-header > div > div",
    ".container-header > div > button",
    ".container-header > nav > div",
  ];

  const tl = gsap.timeline();

  tl.to([...navItemsSelectors], {
    opacity: 0,
    duration: 0.25,
    ease: "power2.out",
  })
    .fromTo(
      wrapperSelector,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.4,
        delay: 0.125,
        ease: "power2.out",
        onComplete: onClick,
      }
    )
    .fromTo(
      logoSelector,
      {
        scale: 200,
        xPercent: 2600,
        yPercent: 3400,
      },
      {
        scale: 1,
        xPercent: -50,
        yPercent: -50,
        duration: 0.6,
        ease: "circ.out",
      },
      "<+=25%"
    )
    .fromTo(
      ".generalPagesWrap",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.6,
        ease: "power2.out",
        onComplete: () => {
          router.push(href);
          gsap.set([...navItemsSelectors], { opacity: 1 });
          container.style.overflowY = "hidden";
          document.body.style.overflowY = "hidden";
          container.style.maxHeight = "100vh";
          document.body.style.maxHeight = "100vh";
          container.style.maxWidth = "100vw";
          document.body.style.maxWidth = "100vw";
        },
      },
      "<+=25%"
    );
};
