import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { animatePageOut } from "../../TransitionAnimations";
import { useRefStore } from "../../../store/ref-store";

import { ScrollTrigger } from "gsap/ScrollTrigger";

interface LinkTransitionProps extends LinkProps {
  children: ReactNode;
  fromNav?: boolean;
  href: string;
  className: string;
  onMouseEnter?: (() => void) | ((e: any) => void);
  onMouseLeave?: (() => void) | ((e: any) => void);
  onMouseMove?:
    | (() => void)
    | ((e: any) => gsap.core.Tween)
    | ((e: any) => void);
  onClick?: () => void;
}

/**
 * A component to animate a transition when navigating between pages.
 * When a user clicks the LinkTransition, a page transition animation is triggered.
 *
 * @param {string} href - The route to where the user is going to navigate.
 * @param {string} className - The className for the element to be styled.
 * @param {boolean} fromNav - (Optional) Indicates whether the user navigates from the navbar. Defaults to false.
 * @param {() => void} onMouseEnter - (Optional) Function to handle mouse enter event.
 * @param {() => void} onMouseLeave - (Optional) Function to handle mouse leave event.
 * @param {(() => void) | ((e: any) => gsap.core.Tween)} onMouseMove - (Optional) Function to handle mouse move event.
 * @param {() => void} onClick - (Optional) Custom function to handle click event.
 *
 * @return {JSX.Element} The rendered component.
 *
 * @example
 * <LinkTransition href={"/join-us"} className={styles.navLink}>
 *    <p>Join us</p>
 * </LinkTransition>
 */

export default function LinkTransition({
  children,
  href,
  className,
  fromNav = false,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  onClick,
  ...props
}: LinkTransitionProps) {
  const router = useRouter();
  const pathname = usePathname();

  const handleTransition = (e) => {
    e.preventDefault();

    useRefStore.setState({ fromPopState: false });

    if (pathname !== href) {
      ScrollTrigger.killAll();
      animatePageOut(href, router, fromNav, onClick);

      if (onClick && !fromNav) {
        onClick();
      }
    }
  };

  return (
    <Link
      href={href}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onClick={handleTransition}
      {...props}
    >
      {children}
    </Link>
  );
}
